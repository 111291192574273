import { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { ReactComponent as ReactStar } from "images/bullet-point.svg";

import libroImg from "images/libro.webp";

export default function Libro() {
  const [show, setShow] = useState(false);
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      if (show) {
        ref.current.style.maxHeight = "100%";
      } else {
        ref.current.style.maxHeight = null;
      }
    }
  }, [show]);

  return (
    <section id="libro">
      <ul className="flex items-center justify-around bg-[#cb6109] py-5 text-base text-white md:gap-[480px] md:px-52">
        <li
          className="menu-secondary group flex items-center gap-4"
          onClick={() => setShow((prev) => !prev)}
        >
          <Star className="inline" />
          <button className="min-w-fit">
            <strong>Leer</strong> sinopsis
          </button>
          <Star className="inline" />
        </li>
        <li className="menu-secondary flex cursor-pointer items-center gap-4">
          <Star className="inline" />
          <a
            href="https://www.amazon.com/stores/Lic.-Lizette-Liz-Morales-Navedo/author/B0C7GC4LQ7"
            className="min-w-fit"
            aria-label="Ver libro en Amazon"
          >
            <strong>Ver</strong> libro en Amazon
          </a>
          <Star className="inline" />
        </li>
      </ul>
      <div className="accordion-wrapper px-10" data-active={show}>
        <div ref={ref} className="accordion" data-active={show}>
          <div className="flex flex-col items-center justify-center text-xl md:flex-row-reverse">
            <div className="p-10">
              <h2 className="mb-4 font-display text-4xl text-[#cb6109]">
                Sinopsis
              </h2>
              <p>
                Más que un libro, <strong>La Sanación Absoluta</strong> es una
                invitación a reconocer que la espiritualidad no puede vivir
                separada del cuerpo y la mente. Profundizaremos en los miedos,
                en las emociones y el equilibrio; determinaremos que el estrés
                no es normal y que el sufrimiento es solo una opción entre
                muchas alternativas; discutiremos qué es y cuán efectiva es la
                Psicoterapia, por dónde y cómo comenzar de nuevo y si existe o
                no un viaje hacia la sanación absoluta, a través del
                autoanálisis guiado.
              </p>
              <br />
              <ul className="ml-6 list-disc">
                <li>
                  El libro es una guía introspectiva que te encamina hacia la
                  paz interior.
                </li>
                <li>
                  El propósito del diseño y contenido es explorar en las
                  emociones y los sentimientos del lector.
                </li>
                <li>
                  El objetivo es comprender, aprender, crecer, sentir, tomar
                  decisiones y crear nuevos enfoques e ideas de vida. (Cambiar
                  los paradigmas).
                </li>
                <li>
                  En conclusión, cuando se integran las técnicas tradicionales
                  con otras terapias alternativas, se restablece el balance y la
                  armonía del cuerpo físico, energético, mental, emocional y
                  espiritual.
                </li>
              </ul>
              <a
                className="desktop my-10 ml-auto w-fit rounded-full bg-[#cb6109] px-6 py-2 text-sm text-white"
                href="https://www.amazon.com/stores/Lic.-Lizette-Liz-Morales-Navedo/author/B0C7GC4LQ7"
                aria-label="Ver libro en Amazon"
              >
                <span>
                  <strong>Ver</strong> libro en Amazon
                </span>
              </a>
            </div>
            <img
              loading="lazy"
              className="object-contain"
              width={476}
              height={329}
              src={libroImg}
              alt="Libro: La sanación absoluta"
            />
            <a
              className="mobile my-10 rounded-full bg-[#cb6109] px-6 py-2 text-sm text-white"
              href="https://www.amazon.com/stores/Lic.-Lizette-Liz-Morales-Navedo/author/B0C7GC4LQ7"
              aria-label="Ver libro en Amazon"
            >
              <span>
                <strong>Ver</strong> libro en Amazon
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

function Star({ className }) {
  return (
    <ReactStar
      className={classNames("inline", className)}
      width={10}
      height="100%"
    />
  );
}
