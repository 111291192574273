import logo from "images/logo.webp";
import mainPicture from "images/main-picture.webp";
import mainBg from "images/main_bg.webp";
import mainTL from "images/main_tl.webp";
import mainBL from "images/main_bl.webp";
import mainTR from "images/main_tr.webp";
import oracionL from "images/oracion_l.webp";
import oracionBgL from "images/oracion_bg_l.webp";
import oracionC from "images/oracion_c.webp";
import oracionR from "images/oracion_r.webp";
import oracionBgR from "images/oracion_bg_r.webp";
import sanacionBgT from "images/sanacion_bg_t.webp";
import sanacionBgB from "images/sanacion_bg_b.webp";
import sanacionL from "images/sanacion_l.webp";
import sanacionR from "images/sanacion_r.webp";
import sanacionMobile from "images/la-sanacion-absoluta.webp";
import sanacionDesktop from "images/la-sanacion.webp";
import Audio from "./audio";
import Animate from "./animate";
import CardDesktop from "./card-desktop";
import OracionDesktop from "./oracion-desktop";
import SanacionDesktop from "./sanacion-desktop";

export default function Main() {
  return (
    <section id="main">
      <CardMobile />
      <CardDesktop />

      <OracionMobile />
      <OracionDesktop />

      <Audio
        className="mx-auto"
        src="/audio/oracion-de-sanacion.mp3"
        controls
        preload="none"
      />

      <SanacionMobile />
      <SanacionDesktop />

      <div className="mobile relative">
        <img
          loading="lazy"
          className="absolute bottom-20 -z-10"
          src={sanacionBgT}
          alt=""
        />
        <img
          loading="lazy"
          className="absolute bottom-0 -z-10 translate-y-1/4"
          src={sanacionBgB}
          alt=""
        />
        <img
          loading="lazy"
          className="mobile h-auto w-full"
          src={sanacionMobile}
          alt=""
          width={431}
          height={619}
        />
      </div>

      <div className="desktop relative">
        <img
          loading="lazy"
          className="absolute left-1/2 top-1/2 -z-10 -translate-y-full scale-150"
          src={sanacionBgT}
          alt=""
        />
        <img
          loading="lazy"
          className="absolute left-1/2 top-1/2 -z-10 -translate-x-1/2 -translate-y-1/3 scale-150"
          src={sanacionBgB}
          alt=""
        />
        <img
          loading="lazy"
          className="mx-auto w-full max-w-7xl"
          src={sanacionDesktop}
          alt=""
          width={1365}
          height={790}
        />
      </div>
    </section>
  );
}

function SanacionMobile() {
  return (
    <div className="mobile relative h-60 w-full md:h-96">
      <Animate
        className="mobile absolute top-0 -translate-x-full"
        animation={{
          hidden: { left: 0 },
          visible: { left: 186 },
        }}
        transition={{ duration: 2, ease: "circOut" }}
      >
        <img loading="lazy" src={sanacionL} alt="ilustración" />
      </Animate>

      <Animate
        className="mobile absolute translate-x-full"
        animation={{
          hidden: { right: 0 },
          visible: { right: 186 },
        }}
        transition={{ duration: 2, ease: "circOut" }}
      >
        <img loading="lazy" src={sanacionR} width={186} alt="ilustración" />
      </Animate>
    </div>
  );
}

function OracionMobile() {
  return (
    <>
      <div className="mobile relative min-h-[24rem] w-full md:h-40">
        <Animate
          className="absolute left-0 top-1/2 -z-20 -translate-y-1/2"
          animation={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          transition={{ duration: 2, ease: "circOut" }}
        >
          <img loading="lazy" src={oracionBgL} alt="" />
        </Animate>

        <Animate
          className="absolute -translate-x-1/2"
          animation={{
            hidden: { left: 0 },
            visible: { left: 78 },
          }}
          transition={{ duration: 2, ease: "circOut" }}
        >
          <img loading="lazy" src={oracionL} alt="" />
        </Animate>

        <img
          loading="lazy"
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          src={oracionC}
          alt=""
        />

        <Animate
          className="absolute right-0 top-1/2 -z-20 -translate-y-1/2"
          animation={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          transition={{ duration: 2, ease: "circOut" }}
        >
          <img loading="lazy" src={oracionBgR} alt="" />
        </Animate>

        <Animate
          className="absolute translate-x-full translate-y-1/2"
          animation={{
            hidden: { right: 0 },
            visible: { right: 162 },
          }}
          transition={{ duration: 2, ease: "circOut" }}
        >
          <img loading="lazy" src={oracionR} alt="" />
        </Animate>
      </div>
      <div className="mobile flex-col">
        <h2 className="mx-auto text-center font-display text-4xl">
          Oración de <br /> La sanación absoluta
        </h2>
        <div className="py-10">
          <p className="mx-10 text-center md:mx-auto md:max-w-lg">
            Ofrezco un milagro en el nombre de Jesús para todos los que están
            buscando <br />
            La sanación absoluta.
          </p>
          <p className="text-center text-3xl">Amén</p>
        </div>
      </div>
    </>
  );
}

function CardMobile() {
  return (
    <div className="mobile flex flex-col">
      <img
        loading="lazy"
        className="mx-auto max-w-[220px]"
        src={logo}
        width={451}
        height={406}
        alt="logo"
      />
      <div className="relative">
        <Animate
          className="absolute left-1/2 top-1/2 -z-10 -translate-x-3/4 -translate-y-1/2 scale-[3]"
          animation={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          transition={{ duration: 2, ease: "circOut" }}
        >
          <img src={mainBg} width={608} height={695} alt="" />
        </Animate>
        <img
          loading="lazy"
          className=" mx-auto py-10"
          src={mainPicture}
          width={314}
          height={486}
          alt=""
        />

        <Animate
          className="absolute -top-40 left-0 z-0"
          animation={{
            hidden: { top: -160 - 340, left: -264 },
            visible: { top: -160, left: 0 },
          }}
          transition={{ duration: 2, ease: "circOut" }}
        >
          <img
            loading="lazy"
            src={mainTL}
            width={264}
            height={304}
            alt="ilustración"
          />
        </Animate>

        <Animate
          className="absolute"
          animation={{
            hidden: { bottom: -80 - 318, left: -227 },
            visible: { bottom: -80, left: 0 },
          }}
          transition={{ duration: 2, ease: "circOut" }}
        >
          <img
            loading="lazy"
            src={mainBL}
            width={227}
            height={318}
            alt="ilustración"
          />
        </Animate>

        <Animate
          className="absolute right-0 top-20"
          animation={{
            hidden: { top: -80 - 350, right: -173 },
            visible: { top: -80, right: 0 },
          }}
          transition={{ duration: 2, ease: "circOut" }}
        >
          <img
            loading="lazy"
            src={mainTR}
            width={173}
            height={350}
            alt="ilustración"
          />
        </Animate>
      </div>
      <p className="mx-10 text-center">
        Es tu responsabilidad recordar quién eres y volver a{" "}
        <span className="font-display">Ser</span>
      </p>
    </div>
  );
}
