import classNames from "classnames";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

export default function Animate({ className, children, animation, ...rest }) {
  const [isInView, setInView] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    setInView(inView);
  }, [inView]);

  return (
    <motion.div
      className={classNames(className, { visible: isInView })}
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={animation}
      {...rest}
    >
      {children}
    </motion.div>
  );
}
