import Animate from "./animate";

import logo from "images/logo.webp";
import mainBg from "images/desktop_main_bg.webp";
import mainL from "images/desktop_main_l.webp";
import mainBR from "images/desktop_main_br.webp";
import mainTR from "images/desktop_main_tr.webp";
import mainMR from "images/desktop_main_mr.webp";

import mainPictureDesktop from "images/main_md.webp";

export default function CardDesktop() {
  return (
    <div className="desktop relative flex w-full items-center justify-center">
      <Animate className="slide-top-left absolute z-20">
        <img
          className="img-slide md:max-h-[24rem] lg:max-h-[28rem]"
          loading="lazy"
          src={mainL}
          width={381}
          height={787}
          alt="ilustración"
        />
      </Animate>

      <Animate className="slide-top-right absolute z-20">
        <img
          className="img-slide md:max-h-[20rem] lg:max-h-[unset]"
          loading="lazy"
          src={mainTR}
          width={214}
          height={400}
          alt="ilustración"
        />
      </Animate>
      <Animate className="slide-mid-right absolute z-20">
        <img
          className="img-slide md:max-h-40 lg:max-h-[13rem]"
          loading="lazy"
          src={mainMR}
          width={209}
          height={275}
          alt="ilustración"
        />
      </Animate>
      <Animate className="slide-bottom-right absolute z-20">
        <img
          className="img-slide md:max-h-60"
          loading="lazy"
          src={mainBR}
          width={483}
          height={469}
          alt="ilustración"
        />
      </Animate>

      <div>
        <img className="mx-auto w-full max-w-sm" src={logo} alt="logo" />
        <p className="mx-10 max-w-xs text-center">
          Es tu responsabilidad recordar quién eres y volver a{" "}
          <span className="font-display">Ser</span>
        </p>
      </div>

      <img
        className="z-10 my-20"
        src={mainPictureDesktop}
        width={403}
        height={632}
        alt=""
      />

      <Animate
        className="absolute left-1/2 top-1/2 z-0 -translate-x-1/4 -translate-y-[60%]"
        animation={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        transition={{ duration: 2, ease: "circOut" }}
      >
        <img
          className="scale-110"
          src={mainBg}
          width={608}
          height={695}
          alt=""
        />
      </Animate>
    </div>
  );
}
