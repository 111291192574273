import Animate from "./animate";
import oracionL from "images/desktop_oracion_l.webp";
import oracionBgL from "images/desktop_oracion_bg_l.webp";
import oracionC from "images/oracion_c.webp";
import oracionR from "images/desktop_oracion_r.webp";
import oracionBgR from "images/desktop_oracion_bg_r.webp";

export default function OracionDesktop() {
  return (
    <div className="desktop relative min-h-[40rem] w-full">
      <Animate className="absolute left-0 top-1/2 -translate-x-1/3 -translate-y-1/2">
        <img
          className="fade-in"
          loading="lazy"
          src={oracionBgL}
          alt=""
          width={606}
          height={950}
        />
      </Animate>

      <Animate className="slide-mid-left absolute">
        <img
          className="img-slide"
          loading="lazy"
          src={oracionL}
          alt=""
          width={537}
          height={705}
        />
      </Animate>

      <div className="flex w-full flex-col items-center justify-center">
        <img className="mx-auto" loading="lazy" src={oracionC} alt="" />

        <div className="z-20">
          <h2 className="mx-auto text-center font-display text-4xl">
            Oración de <br /> La sanación absoluta
          </h2>
          <div className="py-10">
            <p className="mx-10 text-center md:mx-auto md:max-w-lg">
              Ofrezco un milagro en el nombre de Jesús para todos los que están
              buscando <br />
              La sanación absoluta.
            </p>
            <p className="text-center text-3xl">Amén</p>
          </div>
        </div>
      </div>

      <Animate className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-1/3">
        <img
          className="fade-in"
          loading="lazy"
          src={oracionBgR}
          alt=""
          width={606}
          height={950}
        />
      </Animate>

      <Animate className="slide-mid-right absolute">
        <img
          className="img-slide"
          loading="lazy"
          src={oracionR}
          alt=""
          width={538}
          height={762}
        />
      </Animate>
    </div>
  );
}
