import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import one from "images/carousel/1.webp";
import two from "images/carousel/2.webp";
import three from "images/carousel/3.webp";
import four from "images/carousel/4.webp";
import five from "images/carousel/5.webp";

export default function About() {
  return (
    <section
      id="biografia"
      className="flex-col items-center bg-gradient-to-b from-[#f3eee5] to-white to-10% px-10 pb-60 text-[#5B4C4C]"
    >
      <header className="mx-auto flex max-w-2xl flex-col items-center justify-center">
        <h2 className="my-2 text-center font-display text-5xl">
          Liz Morales Navedo
        </h2>
        <h3 className="text-4xl font-light">Psicoterapeuta</h3>
      </header>

      <Carousel
        className="mx-auto my-10 max-w-4xl"
        autoPlay
        infiniteLoop
        showStatus={false}
        showThumbs={false}
      >
        <img src={one} alt="" />
        <img src={two} alt="" />
        <img src={three} alt="" />
        <img src={four} alt="" />
        <img src={five} alt="" />
      </Carousel>

      <div className="mx-auto max-w-5xl text-center text-xl md:text-justify">
        <p>
          <span className="font-display text-2xl">Liz Morales Navedo,</span>
          <br />
          es licenciada en psicología, autora y escritora con una vasta
          experiencia en las artes corporales del ballet clásico, maestra e
          instructora en la práctica del yoga.
        </p>
        <br />
        <p>
          En su profesión integra todos los conocimientos y estudios adquiridos
          en la implementación de terapias convencionales, alternativas y
          espirituales inspiradas en Un curso de milagros.
        </p>
        <br />
        <p>
          Su especialidad es la psicología introspectiva, modelo de terapia que
          profundiza y examina el sentido y propósito existencial; con el
          objetivo fundamental de restablecer el balance y la armonía, la
          relajación, la atención plena y la experiencia de unidad o
          espiritualidad en el menor tiempo posible.
        </p>
        <br />
        <p>
          Como parte de sus aportaciones ha diseñado un modelo de yoga
          terapéutica integral basadas en evidencias y análisis en la conexión
          del cuerpo físico, psicología mental, emocional y espiritual con el
          fin de liberar emociones no procesadas.
        </p>
        <p>
          La yoga terapéutica es un programa que integra posturas específicas
          para balancear los centros energéticos del cuerpo físico y mental con
          secuencias en movimientos sincronizados con la respiración
          diafragmática o profunda, el uso de la técnica introspectiva,{" "}
          <strong>“NOVA Tapping”</strong>, visualización, meditación y
          aromaterapia con el objetivo de potenciar la frecuencia vibratoria o
          el campo energético de los órganos del cuerpo.
        </p>
      </div>
    </section>
  );
}
