import NavBar from "components/navbar";
import Main from "components/main";
import Libro from "components/libro";
import Meditacion from "components/meditacion";
import Footer from "components/footer";
import hrImg from "images/hr.webp";
import About from "components/about";

export default function App() {
  return (
    <div className="mx-auto bg-[#f3eee5]">
      <NavBar />
      <main className="overflow-x-clip text-2xl">
        <Main />
        <Libro />
        <Meditacion />
        <img
          loading="lazy"
          className="mx-auto mb-24 max-w-xs"
          width={488}
          height={4}
          src={hrImg}
          alt=""
        />
        <About />
      </main>
      <Footer />
    </div>
  );
}
