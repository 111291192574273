import Animate from "./animate";

import sanacionL from "images/desktop_sanacion_l.webp";
import sanacionR from "images/desktop_sanacion_r.webp";

export default function SanacionDesktop() {
  return (
    <div className="desktop relative h-96 w-full">
      <Animate className="slide-mid-left absolute">
        <img
          className="img-slide"
          loading="lazy"
          src={sanacionL}
          width={605}
          height={468}
          alt="ilustración"
        />
      </Animate>

      <Animate className="slide-mid-right absolute">
        <img
          className="img-slide"
          loading="lazy"
          src={sanacionR}
          width={577}
          height={385}
          alt="ilustración"
        />
      </Animate>
    </div>
  );
}
