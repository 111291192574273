import Social from "./social";

import tl from "images/footer_tl.webp";
import bl from "images/footer_bl.webp";
import br from "images/footer_br.webp";
import desktopTL from "images/desktop_footer_tl.webp";
import desktopBL from "images/desktop_footer_l.webp";
import desktopBR from "images/desktop_footer_r.webp";
import Animate from "./animate";

export default function Footer() {
  return (
    <footer className="flex flex-col overflow-x-clip bg-white">
      <div className="relative flex items-center justify-center bg-black bg-opacity-10 fill-white py-6 text-white md:px-20 md:py-12">
        <Social className="menu-secondary" ul=" gap-8 md:gap-20" size={40} />
        <ImagesMobile />
        <ImagesDesktop />
      </div>
      <p className="my-6 text-center text-[#b1a8a3]">
        <strong>copyright 2023</strong>
      </p>
    </footer>
  );
}

function ImagesMobile() {
  return (
    <>
      <img
        loading="lazy"
        className="mobile absolute -top-10 right-4"
        src={tl}
        width={83}
        height={69}
        alt="ilustración"
      />
      <img
        loading="lazy"
        className="mobile absolute -bottom-9 left-0"
        width={185}
        height={158}
        src={bl}
        alt="ilustración"
      />
      <img
        loading="lazy"
        className="mobile absolute -bottom-9 right-0"
        width={159}
        height={136}
        src={br}
        alt="ilustración"
      />
    </>
  );
}

function ImagesDesktop() {
  return (
    <>
      <img
        loading="lazy"
        className="desktop absolute right-0 top-1/2 -translate-x-[200%] -translate-y-full md:max-h-40 lg:max-h-none"
        src={desktopTL}
        alt="ilustración"
      />
      <Animate className="slide-bottom-left absolute">
        <img
          loading="lazy"
          className="desktop img-slide !max-h-[180px]"
          src={desktopBL}
          alt="ilustración"
        />
      </Animate>
      <Animate className="slide-top-right absolute">
        <img
          loading="lazy"
          className="desktop img-slide !max-h-[180px]"
          src={desktopBR}
          alt="ilustración"
        />
      </Animate>
    </>
  );
}
