import { useCallback, useEffect, useRef, useState } from "react";
import { IoPlaySharp, IoPauseSharp } from "react-icons/io5";
import { FiDownload } from "react-icons/fi";
import {
  IoIosVolumeOff,
  IoIosVolumeLow,
  IoIosVolumeHigh,
} from "react-icons/io";

import { formatTime } from "utils";

export default function Audio({ src, downloadable = true, className }) {
  const audioRef = useRef();
  const barRef = useRef();

  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleNext = () => {
    audioRef.current.currentTime = 0;
    setIsPlaying(false);
  };

  return (
    <div className={className}>
      <DisplayTrack
        audioRef={audioRef}
        barRef={barRef}
        src={src}
        setDuration={setDuration}
        handleNext={handleNext}
      />
      <Controls
        src={src}
        audioRef={audioRef}
        barRef={barRef}
        duration={duration}
        setTimeProgress={setTimeProgress}
        handleNext={handleNext}
        downloadable={downloadable}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
      >
        <ProgressBar
          audioRef={audioRef}
          barRef={barRef}
          timeProgress={timeProgress}
          duration={duration}
        />
      </Controls>
    </div>
  );
}

function DisplayTrack({ src, audioRef, setDuration, barRef, handleNext }) {
  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration;
    setDuration(seconds);
    barRef.current.max = seconds;
  };

  return (
    <div>
      <audio
        className="hidden"
        ref={audioRef}
        src={src}
        controls
        onLoadedMetadata={onLoadedMetadata}
        onEnded={handleNext}
      />
    </div>
  );
}

function Controls({
  src,
  downloadable,
  audioRef,
  barRef,
  duration,
  setTimeProgress,
  isPlaying,
  setIsPlaying,
  children,
}) {
  const [volume, setVolume] = useState(60);
  const [muteVolume, setMuteVolume] = useState(false);

  const playAnimationRef = useRef();

  const repeat = useCallback(() => {
    const currentTime = audioRef.current.currentTime;
    setTimeProgress(currentTime);
    barRef.current.value = currentTime;
    barRef.current.style.setProperty(
      "--range-progress",
      `${(barRef.current.value / duration) * 100}%`
    );

    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [audioRef, duration, barRef, setTimeProgress]);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
      playAnimationRef.current = requestAnimationFrame(repeat);
    } else {
      audioRef.current.pause();
      cancelAnimationFrame(playAnimationRef.current);
    }
  }, [isPlaying, audioRef, repeat]);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [isPlaying, audioRef, repeat]);

  useEffect(() => {
    if (audioRef) {
      audioRef.current.volume = volume / 100;
    }
  }, [volume, audioRef]);

  useEffect(() => {
    if (audioRef) {
      audioRef.current.volume = volume / 100;
      audioRef.current.muted = muteVolume;
    }
  }, [volume, audioRef, muteVolume]);

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  return (
    <div className="flex items-center justify-center">
      <div className="flex flex-wrap items-center justify-center gap-2">
        <div className="flex gap-2">
          <button
            className="h-10 rounded-full bg-[#C1272D] p-2"
            onClick={togglePlayPause}
          >
            {isPlaying ? (
              <IoPauseSharp className="fill-white" />
            ) : (
              <IoPlaySharp className="translate-x-[2px] fill-white" />
            )}
          </button>

          {children}
        </div>

        <div className="flex items-center justify-center gap-2">
          <div className="flex items-center justify-center gap-2">
            <button
              className="rounded-full p-2"
              onClick={() => setMuteVolume((prev) => !prev)}
            >
              {muteVolume || volume < 5 ? (
                <IoIosVolumeOff className="fill-[#C1272D]" />
              ) : volume < 40 ? (
                <IoIosVolumeLow className="fill-[#C1272D]" />
              ) : (
                <IoIosVolumeHigh className="fill-[#C1272D]" />
              )}
            </button>
            <input
              className="!w-20"
              type="range"
              min={0}
              max={100}
              value={volume}
              onChange={(e) => setVolume(e.target.value)}
            />
          </div>
          {downloadable ? (
            <a className="p-2" href={src} download>
              <FiDownload className="stroke-[#C1272D]" />
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function ProgressBar({ audioRef, barRef, timeProgress, duration }) {
  const handleProgressChange = () => {
    audioRef.current.currentTime = barRef.current.value;
  };

  return (
    <div className="flex h-10 items-center justify-center gap-2 rounded-full bg-[#C1272D] p-2 px-4 text-sm text-white">
      <input
        ref={barRef}
        type="range"
        defaultValue="0"
        onChange={handleProgressChange}
      />
      {audioRef?.current?.paused ? (
        <span className="w-9">{formatTime(duration)}</span>
      ) : (
        <span className="w-9">{formatTime(timeProgress)}</span>
      )}
    </div>
  );
}
