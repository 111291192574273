import classNames from "classnames";

import Instagram from "images/icons/instagram.png";
import Amazon from "images/icons/amazon.png";
import LinkedIn from "images/icons/linkedin.png";
import WhatsApp from "images/icons/whatsapp.png";

export default function Social({
  size = 30,
  ul,
  className = "menu-primary cursor-pointer",
}) {
  const pxSize = `${size}px`;
  const socials = [
    {
      href: "https://www.instagram.com/lizmoralestherapist",
      children: <img src={Instagram} alt="" style={{ width: pxSize }} />,
      aria: "Instagram",
    },
    {
      href: "https://www.amazon.com/stores/Lic.-Lizette-Liz-Morales-Navedo/author/B0C7GC4LQ7",
      children: <img src={Amazon} alt="" style={{ width: pxSize }} />,
      aria: "Amazon",
    },
    {
      href: "https://www.linkedin.com/in/liz-morales-navedo-795745112",
      children: <img src={LinkedIn} alt="" style={{ width: pxSize }} />,
      aria: "LinkedIn",
    },
    {
      href: "https://wa.me/17862962636",
      children: <img src={WhatsApp} alt="" style={{ width: pxSize }} />,
      aria: "WhatsApp",
    },
  ];

  return (
    <>
      <ul className={classNames("flex gap-12", ul)}>
        {socials.map(({ href, children, aria }) => (
          <li key={href}>
            <a href={href} className={className} aria-label={aria}>
              {children}
            </a>
          </li>
        ))}
      </ul>
    </>
  );
}
