import { CgMenu } from "react-icons/cg";

import Social from "./social";

export default function NavBar() {
  return (
    <>
      <Mobile />
      <nav className="desktop items-center justify-between px-20 py-16">
        <ul className="flex items-center gap-12">
          <li className="menu-primary">
            <a href="#main">Inicio</a>
          </li>
          <li className="list-disc text-xs"></li>
          <li className="menu-primary">
            <a href="#libro">Libro</a>
          </li>
          <li className="list-disc text-xs"></li>
          <li className="menu-primary">
            <a href="#biografia">Biografía</a>
          </li>
        </ul>
        <Social />
      </nav>
    </>
  );
}

function Mobile() {
  return (
    <div className="mobile navbar sticky z-50">
      <div className="navbar-start">
        <div className="dropdown">
          <label tabIndex={0} className="btn-ghost btn">
            <CgMenu size={22} />
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content menu rounded-box menu-sm mt-3 w-52 bg-base-100 p-2 shadow"
          >
            <li className="menu-primary">
              <a href="#main">Inicio</a>
            </li>
            <li className="list-disc text-xs"></li>
            <li className="menu-primary">
              <a href="#libro">Libro</a>
            </li>
            <li className="list-disc text-xs"></li>
            <li className="menu-primary">
              <a href="#biografia">Biografía</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="navbar-end">
        <Social ul="gap-8" />
      </div>
    </div>
  );
}
