import meditationImg from "images/meditation.webp";
import meditacionL from "images/meditacion_l.webp";
import meditacionR from "images/meditacion_r.webp";
import Audio from "./audio";

export default function Meditacion() {
  return (
    <section className="relative flex flex-col items-center bg-[#f3eee5]">
      <img
        loading="lazy"
        className="absolute -left-14 top-1/4 max-w-xs -translate-x-1/2 md:left-1/4"
        src={meditacionL}
        width={687}
        height={687}
        alt=""
      />
      <img
        loading="lazy"
        className="absolute -right-20 top-1/4 w-40 md:right-1/4"
        src={meditacionR}
        width={376}
        height={376}
        alt=""
      />

      <img
        loading="lazy"
        className="mt-10"
        src={meditationImg}
        width={107}
        height={106}
        alt="ilustración"
      />
      <h2 className="mx-auto my-10 text-center font-display text-4xl">
        Meditación para el perdón
      </h2>
      <div className="mx-10 text-center text-xl">
        <p>
          Uno mi mente al Espíritu Santo. <br /> Entra en ella, pon en orden mis
          pensamientos,
          <br /> dame tu confianza y tu honestidad. <br /> Hazte cargo de esto.
          <br />
          Condúceme hasta el lugar de la causa y tráeme la paz.
        </p>
        <br />
        <p>
          Debo haber decidido equivocadamente <br /> porque no estoy en paz.{" "}
          <br /> Yo mismo tomé esa decisión, por lo tanto, <br /> puedo tomar
          otra. Quiero tomar otra decisión porque <br /> deseo estar en paz. No
          me siento culpable porque el <br /> Espíritu Santo, si se lo permito,
          anulará todas las <br /> consecuencias de mi decisión equivocada.
          Elijo <br /> permitírselo al dejar que Él decida <br /> por Dios en mi
          favor. <br /> Padre, <br /> trae a mi memoria el recuerdo de cuando
          cometí el error. <br /> En algún momento de mi vida tuve un
          pensamiento, <br /> dije o hice algo no amoroso en contra de <br />
          un hermano, algo que tú, Padre, <br /> nunca hubieras pensado, dicho o
          hecho.
        </p>
      </div>
      <Audio
        className="mx-auto my-20"
        src="/audio/meditacion-guiada_capitulo-6.mp3"
        controls
        preload="none"
      />
    </section>
  );
}
